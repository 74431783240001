<template>
  <div>
    <b-card title="Veri Aktarımı">
      <b-row>
        <b-col>
          <b-row class="d-flex justify-content-center align-items-center">
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Arama Türü"
                label-for="id_com_crc_type"
              >
                <v-select
                  v-model="dataItem.id_com_crc_type"
                  :options="crcTypes"
                  :reduce="item => item.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Dosya Seçin"
                label-for="file"
              >
                <b-form-file
                  id="file"
                  accept=".xls, .xlsx"
                  browse-text="Dosya Seçin"
                  placeholder="Dosya Seçin"
                  @change="onChange"
                />
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="danger"
                href="https://inll.app/Medusa2/media/templates/musteri-iliskileri-memnuniyet-aramalari-sablon.xlsx?v=3"
                target="_blank"
              >
                <feather-icon icon="DownloadIcon" /> Şablon Dosyasını İndir
              </b-button>
            </b-col>
          </b-row>
          <b-form-group
            label="Çalışma Sayfası Seçiniz"
            label-for="sheets"
          >
            <xlsx-read :file="file">
              <xlsx-sheets>
                <template #default="{sheets}">
                  <v-select
                    id="sheets"
                    v-model="selectedSheet"
                    :options="sheets"
                    placeholder="Seçiniz"
                    :disabled="!file"
                  />
                </template>
              </xlsx-sheets>
            </xlsx-read>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <small>Şablon dosyasına veriler <b class="text-danger">Şablon</b> sayfası örneğine uygun olarak, diğer rehber sayfalardaki bilgiler (Marka,Lokasyon,Araç Markası,Araç Modeli) ile doldurulması gereklidir.</small>
        </b-col>
        <b-col
          v-if="file"
          cols="12"
          md="12"
        >
          <div class="text-center">
            <b-button
              variant="primary"
              :disabled="uploading || !dataItem.id_com_crc_type"
              @click="importDataSave"
            >
              <b-spinner
                v-if="uploading"
                small
              />
              <feather-icon
                v-if="!uploading"
                icon="UploadIcon"
              /> Aktar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="selectedSheet && upload_status === null"
      no-body
    >
      <b-card-header>
        <b-card-title>Ön İzleme</b-card-title>
      </b-card-header>
      <div class="height-500 overflow-scroll">
        <div
          v-if="!tempData"
          class="text-center"
        >
          <b-spinner />
        </div>
        <b-table
          striped
          small
          hover
          :items="tempData"
          class="font-small-1"
        />
      </div>
      <xlsx-read
        :file="file"
        class="hidden"
      >
        <xlsx-table
          :sheet="selectedSheet"
        />
        <xlsx-json :sheet="selectedSheet">
          <template #default="{collection}">
            <div>
              {{ collection }}
              {{ getData(collection) }}
            </div>
          </template>
        </xlsx-json>
      </xlsx-read>
    </b-card>
    <b-card
      v-if="upload_status !== null"
      title="Aktarım Öncesi Kontrol"
    >
      <b-alert
        show
        :variant="upload_status === true ? 'warning':'danger'"
        class="mt-1"
      >
        <div class="alert-body text-center">
          {{ message }}
        </div>
      </b-alert>
      <template v-if="errData.length > 0">
        <b-alert
          v-for="(row,key) in errData"
          :key="key"
          show
          variant="danger"
          class="mt-1"
        >
          <div
            class="alert-body text-center"
          >
            <b>{{ row.line }}.Satır Hata Mesajı: </b>{{ row.message }}
          </div>
        </b-alert>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormFile, BTable, BSpinner, BButton, BAlert, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import {
  XlsxRead, XlsxTable, XlsxSheets, XlsxJson,
} from 'vue-xlsx'
import vSelect from 'vue-select'

export default {
  name: 'CrcExcelImport',
  components: {
    BAlert,
    BSpinner,
    BTable,
    BFormFile,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    vSelect,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      uploading: false,
      transfer: false,
      tempData: null,
      previewData: [],
      errData: [],
      message: null,
      upload_status: null,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      cardModal: {
        status: false,
        index: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },
    crcTypes() {
      return this.$store.getters['crc/crcTypes']
    },
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
    },
    getData(colection) {
      this.tempData = colection
    },
    importDataSave() {
      this.submitStatus = true
      this.uploading = true
      this.$store.dispatch('crc/batchInsert', {
        importData: this.tempData,
        id_com_crc_type: this.dataItem.id_com_crc_type,
      })
        .then(response => {
          this.errData = response.errArr
          this.message = response.message
          this.upload_status = response.status
          if (response.status) {
            this.$swal({
              icon: 'success',
              title: 'Aktarım Başarılı',
              text: 'Verileriniz başarı ile sisteme aktarıldı. Liste ekranından ulaşabilirsiniz.',
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'İşlem Başarısız',
              text: 'Aktarım tablosunda hatalı veriler bulunuyor. Kayıt işlemi öncesinde düzeltilmelidir.',
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          this.uploading = false
        })
    },

    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
