<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab>
        <template #title>
          <feather-icon icon="UploadIcon" />
          Toplu Aktarım
        </template>
        <crc-excel-import />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SearchIcon" />
          Müşteri Seçimi
        </template>
        <crc-customer-selection />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CrcExcelImport from '@/views/Crm/Crc/Components/Excel.vue'
import CrcCustomerSelection from '@/views/Crm/Crc/Components/SearchCustomer.vue'

export default {
  name: 'AddPage',
  components: {
    CrcCustomerSelection,
    CrcExcelImport,
    BTab,
    BTabs,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {

  },
}
</script>
